.home {
    display: flex;
    width: 100%;
    background-color: #ffffff;
}

.background-mobile {
    position: relative;
    width: 100vw;
    margin: 0;
    padding: 0;
    /* Full viewport width */
}

.background-mobile.loading {
    padding-bottom: 100vh;
    /* For a 16:9 aspect ratio image */
}

.overlay-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    /* Ensure it's above the image */
}

.background-desktop {
    position: relative;
    width: 100vw;
    margin: 0;
    padding: 0;
    /* Full viewport width */
}

.background-desktop.loading {
    padding-bottom: 45vw;
    /* For a 16:9 aspect ratio image */
}

.home-contents {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    overflow-y: hidden;
}

.home-contents-desktop {
    display: grid;
    grid-template-rows: auto auto;
    overflow-x: hidden;
    /* padding-top: 5vw */
}

.home-top {
    display: flex
}

.home-title-text {
    margin: 0;
    padding: 0;
    padding-top: 2.65vw;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-sizing: border-box;
    font-size: 5vw;
    font-weight: 700;
    color: #28329f;
    line-height: 4.85vw;
    letter-spacing: 0.1vw;
    -webkit-text-stroke: 0.125vw #7098dc;
}

.home-title-subtext {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-sizing: border-box;
    font-size: 5vw;
    font-weight: 700;
    color: #28329f;
}

.home-topics-set {
    margin: 0;
    padding: 0;
    padding-top: 7.5vw;
    display: grid;
    justify-content: center;
    align-items: center;
    text-align: center;
    grid-template-columns: auto auto;
    font-size: 2vw;
    font-weight: 700;
    color: #11175a;
    line-height: 4.85vw;
    letter-spacing: 0.1vw;
    -webkit-text-stroke: 0.05vw #7098dc;
}

.home-topics-set.top {
    margin: 0 3vw;
    margin-right: 3.5vw;
    justify-content: space-between;
}

.home-topics-set.bottom {
    padding-top: 4vw;
    margin: 0 2vw;
    margin-right: 2.5vw;
    justify-content: space-between;
}

.home-topic-name-box {
    margin: 0;
    padding: 0;
    width: 12.5vw;
    height: 5vw;
    box-sizing: border-box;
    background-color: #ffffff;
    border: 0.2vw solid rgb(255, 255, 255);
}

.home-topic-name-box.left {
    box-shadow: 0.5vw 0.5vw rgb(0, 0, 0),
        0.75vw 0.75vw 0.625vw white;
}

.home-topic-name-box.right {
    box-shadow: -0.5vw 0.5vw rgb(0, 0, 0),
        -0.75vw 0.75vw 0.625vw white;
}

.home-image {
    display: flex;
    width: 100%;
    justify-content: center;
    /* This will horizontally center the child items */
    align-items: center;
    /* This will vertically center the child items */
    box-sizing: border-box;
    padding-left: 2.5vw;
}

.home-image img {
    width: 80%;
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 0 auto;
    border-radius: 1vw;
    background-color: rgb(237, 237, 237);
    box-shadow: 0.75vw 0.75vw 1vw rgba(0, 0, 0, 0.5);
}

.home-description {
    padding-top: 2vw;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.home-bottom {
    display: grid;
    grid-template-rows: auto auto auto auto;
    margin-top: 2vw;
}

.home-eligibility-venue-text {
    flex-direction: column;
    background-color: #83bde3;
    color: #000000;
    border-radius: 1vw;
    text-align: center;
    margin: 3vh 27.5%;
    border: 1vh double #ffffff;
    padding: 2vw 1vw;
}

.home-description-bottom {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 7.5vw;
    margin: 2vw 10vw;
    margin-top: 1vw;
}

.home-description-heading {
    display: flex;
    font-size: 2vw;
    font-weight: 600;
    padding: 1vw 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-description-point {
    background: linear-gradient(to right, #f5f2dc 0%, #faf9f0 50%, #f5f2dc 100%);
    border-radius: 0.5vw;
    text-align: left;
    display: grid;
    grid-template-columns: 12% 86%;
    column-gap: 2%;
    padding: 1vw;
    margin: 1vw 0;
}

.home-description-number-circle-outside {
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-description-number-circle {
    border-radius: 50%;
    background-color: #003251;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5vw;
    height: 2.5vw;
}

.home-description-number {
    font-size: 1.5vw;
    color: #ffffff;
}

.home-description-text {
    font-size: 1.25vw;
    color: #000000;
}

.home-contents-mobile {
    display: grid;
    grid-template-rows: auto auto;
    row-gap: 1vh;
    overflow-x: hidden;
    /* padding: 15vh 1.5vh;
    padding-bottom: 5vh; */
}

.mobile-home-top {
    display: grid;
    grid-template-rows: auto auto auto auto;
}

.mobile-home-description-top {
    width: 100%;
    padding-top: 2vh;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #ffffff;
}

.mobile-home-title-text {
    margin: 0;
    padding: 0;
    padding-top: 5vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-sizing: border-box;
    font-size: 18.5vw;
    font-weight: 700;
    color: #ffffff;
    line-height: 18.5vw;
    letter-spacing: 0.2vw;
    pointer-events: none;
}

.mobile-home-title-image {
    padding-top: 5vh;
    display: flex;
    width: 100%;
    justify-content: center;
    /* This will horizontally center the child items */
    align-items: center;
    /* This will vertically center the child items */
    box-sizing: border-box;
}

.mobile-home-title-image img {
    width: 80%;
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 0 auto;
}

.mobile-home-class-title {
    display: flex;
    color: #ffffff;
    letter-spacing: 0.075vw;
    font-weight: 600;
    background-color: #1e273d;
    border-radius: 1.5vh;
    white-space: nowrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 3vh;
    height: 7.5vh;
    margin: 0 25%;
    margin-top: 2.5vh;
    pointer-events: none;
}

.mobile-home-buttons {
    display: grid;
    padding-top: 7.5vw;
    grid-template-rows: 1fr;
    justify-content: center;
    justify-items: center;
    align-items: center;
}

.mobile-homepage-syllabus-link-container {
    display: flex;
    color: #ffffff;
    letter-spacing: 0.075vw;
    font-weight: 600;
    background-color: #1b1b1b;
    border-radius: 1.5vh;
    white-space: nowrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0vh 12vw;
    font-weight: 600;
    font-size: 2.25vh;
    height: 7.5vh;
    border: 1vh double #ffffff;
}

.mobile-home-description-bottom {
    padding: 5vh 0vh;
    padding-top: 2vh;
    justify-content: center;
    align-items: center;
    text-align: left;
    font-size: 2vh;
    font-weight: 400;
}

.mobile-home-description-point {
    background: linear-gradient(to right, #f5f2dc 0%, #faf9f0 50%, #f5f2dc 100%);
    display: grid;
    grid-template-columns: 1fr 5fr;
    padding: 1.5vh 2vh;
    margin: 2vh 0;
    border-radius: 0.75vh;
    pointer-events: none;
}

.mobile-home-description-heading {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 5vw;
    font-weight: 600;
    padding: 4vh 0;
    white-space: nowrap;
    pointer-events: none;
}

.mobile-home-description-number-circle-outside {
    display: flex;
    justify-content: center;
    align-items: center;
}

.mobile-home-description-number-circle {
    border-radius: 50%;
    background-color: #004772;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 7.5vw;
    height: 7.5vw;
    margin: 2.5vw;
    margin-left: 0vw;
    margin-right: 5vw;
}

.mobile-home-description-number {
    font-size: 3.5vw;
    color: #ffffff;
    pointer-events: none;
}

.mobile-home-bottom {
    display: grid;
    padding: 0vh 5vh;
    padding-bottom: 5vh;
    grid-template-rows: auto auto auto auto;
}

.purchase-book-container {
    display: flex;
    flex-direction: column;
    background: linear-gradient(to right, #680e22 0%, #983b36 50%, #680e22 100%);
    color: #ffffff;
    text-align: center;
    border-radius: 2.5vw;
    width: 65vw;
    height: 4vw;
    font-size: 1.5vw;
    align-items: center;
    justify-content: center;
    padding-bottom: 1vh;
    padding-right: 1vh;
    margin-left: 17.5vw;

}

.mobile-purchase-book-container {
    flex-direction: column;
    color: #000000;
    text-align: center;
    width: 75vw;
    padding-bottom: 1vh;
    padding-right: 1vh;
}

.mobile-purchase-book-content {
    font-size: 2.5vh;
    font-weight: 400;
    padding: 2vh 0;
    background-color: #d0ecfa;
    box-shadow: 1vh 1vh;
}

.mobile-home-eligibility-venue-text {
    flex-direction: column;
    background-color: #83bde3;
    color: #000000;
    border-radius: 0.5vh;
    text-align: center;
    border: 1vh double #ffffff;
    padding: 2vh 3vh;
    margin: 0;
    pointer-events: none;
}