.mobile-syllabus {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    /* Ensure there are no default margins */
    /* Use the full viewport height */
    /* background: linear-gradient(to bottom, #00063d 0%, #00063d 10%, #057490 10%, #057490 75%, #00063d 75%, #00063d 100%); */
    transition: opacity 0.3s ease;
    overflow: auto;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    margin-bottom: 0;
}

.mobile-syllabus-content {
    width: 100%;
    display: grid;
    grid-template-rows: 10vh 1fr 25vh;
    /* The middle row with 1fr will take the remaining space */
    grid-gap: 0px;
    height: 100%;
    /* Ensuring the container fills the viewport */
}

.mobile-text-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.mobile-text-logo-img {
    width: auto;
    height: 8vh;
    padding-left: 1vw;
}

.mobile-top-row {
    height: 10vh;
    width: 100%;
    display: grid;
    grid-template-columns: 55% 45%;
    background-color: #00063d;
}

.mobile-class-workbook-link {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 2vh;
    font-weight: 600;
    color: #ffffff;
    border: 0.1vh solid #ffffff;
    border-radius: 0.5vh;
    margin: 1.5vh;
    background-color: #000000;
    /*043a36,0b3d5c*/
}

.mobile-controls-row {
    display: grid;
    grid-template-rows: 50% 50%;
    padding: 0 3vh;
    background-color: #00063d;
}

.mobile-syllabus-controls {
    display: grid;
    grid-template-rows: 50% 50%;
    height: 12vh;
}

.mobile-syllabus-title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.5vh;
    font-weight: 600;
    color: #ffffff;
    pointer-events: none;
}

.mobile-syllabus-topic-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
    column-gap: 1vh;
}

.mobile-syllabus-topic-button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2vh;
    font-weight: 500;
    color: #dfdfdf;
    border: 0.075vh solid #b3b3b3;
    border-radius: 0.5vh;
    white-space: nowrap;
    height: 4vh;
    background-color: #71717127;
    /*141414,71717127*/
    cursor: pointer;
}

.mobile-class-controls {
    display: grid;
    grid-template-columns: 2fr 3fr 2fr;
    align-items: center;
    justify-items: center;
    margin: 2vh 0vh;
    max-height: 10vh;
    column-gap: 0.25vw;
    border-radius: 0.5vw;
    background: linear-gradient(to right, #00063d 0%, #054890 45%, #054890 55%, #00063d 100%);
}

@keyframes nextColorChange {
    0% {
        background: linear-gradient(to right, #00063d 0%, #054890 45%, #054890 55%, #00063d 100%);
    }

    15% {
        background: linear-gradient(to right, #00063d 0%, #054890 45%, #1c64b1 55%, #00063d 100%);
    }

    30% {
        background: linear-gradient(to right, #00063d 0%, #054890 45%, #347ece 55%, #00063d 100%);
    }

    45% {
        background: linear-gradient(to right, #00063d 0%, #054890 45%, #569de8 55%, #00063d 100%);
    }

    50% {
        background: linear-gradient(to right, #00063d 0%, #054890 45%, #7bbbff 55%, #00063d 100%);
    }

    55% {
        background: linear-gradient(to right, #00063d 0%, #054890 45%, #569de8 55%, #00063d 100%);
    }

    70% {
        background: linear-gradient(to right, #00063d 0%, #054890 45%, #347ece 55%, #00063d 100%);
    }

    85% {
        background: linear-gradient(to right, #00063d 0%, #054890 45%, #1c64b1 55%, #00063d 100%);
    }

    100% {
        background: linear-gradient(to right, #00063d 0%, #054890 45%, #054890 55%, #00063d 100%);
    }
}

.next-background-animation {
    animation: nextColorChange 0.25s forwards;
    /* Play animation once and hold the final state */
}

@keyframes prevColorChange {
    0% {
        background: linear-gradient(to right, #00063d 0%, #054890 45%, #054890 55%, #00063d 100%);
    }

    15% {
        background: linear-gradient(to right, #00063d 0%, #1c64b1 45%, #054890 55%, #00063d 100%);
    }

    30% {
        background: linear-gradient(to right, #00063d 0%, #347ece 45%, #054890 55%, #00063d 100%);
    }

    45% {
        background: linear-gradient(to right, #00063d 0%, #569de8 45%, #054890 55%, #00063d 100%);
    }

    50% {
        background: linear-gradient(to right, #00063d 0%, #7bbbff 45%, #054890 55%, #00063d 100%);
    }

    55% {
        background: linear-gradient(to right, #00063d 0%, #569de8 45%, #054890 55%, #00063d 100%);
    }

    70% {
        background: linear-gradient(to right, #00063d 0%, #347ece 45%, #054890 55%, #00063d 100%);
    }

    85% {
        background: linear-gradient(to right, #00063d 0%, #1c64b1 45%, #054890 55%, #00063d 100%);
    }

    100% {
        background: linear-gradient(to right, #00063d 0%, #054890 45%, #054890 55%, #00063d 100%);
    }
}

.prev-background-animation {
    animation: prevColorChange 0.25s forwards;
    /* Play animation once and hold the final state */
}

.mobile-class-controls.next-class-clicked {
    background: linear-gradient(to right, #00063d 0%, #054890 45%, #7bbbff 55%, #00063d 100%);
}

.mobile-class-controls.prev-class-clicked {
    background: linear-gradient(to right, #00063d 0%, #7bbbff 45%, #054890 55%, #00063d 100%);
}

.mobile-class-control-prev-button {
    display: flex;
    justify-content: center;
    align-items: center;
}

.mobile-class-control-prev-button img {
    height: 7.5vh;
    transition: 0;
    width: auto;
}

.mobile-class-control-class-display {
    display: grid;
    grid-template-rows: 40% 60%;
    align-items: center;
    justify-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    border-radius: 0.5vw;
    text-align: center;
    color: #000000;
    background-color: #ffffffd3;
    border-radius: 0.5rgba(225, 225, 225, 0.686);
    pointer-events: none;
}

.mobile-class-control-class-display-number {
    align-self: flex-end;
    font-size: 4.5vh;
    font-weight: 700;
}

.mobile-class-control-next-button {
    display: flex;
    justify-content: center;
    align-items: center;
}

.mobile-class-control-next-button img {
    height: 7.5vh;
    width: auto;
}

.mobile-syllabus-bottom-container {
    padding: 3vh;
    padding-bottom: 1vh;
    padding-top: 3vh;
    background-color: #057490;
    overflow-y: auto;
    /* will add vertical scroll if the content exceeds max-height */
}

.mobile-syllabus-bottom-container::-webkit-scrollbar {
    display: none;
    /* Hide scrollbar for Chrome, Safari, and Opera */
}