html, body {
    height: 100%;
    width: 100%;
    margin: 0;
    font-family: var(--font-family);
}

:root {
    --font-family: 'Roboto Slab', serif;
    --color-fontColor: #000000;
}

/* Using a class to apply Tinos font to specific elements */
.tinos-font {
    font-family: 'Tinos', serif;
}

/* If you want to use the italic variant of Tinos */
.tinos-italic {
    font-family: 'Tinos', serif;
    font-style: italic;
}

a,
a:link,
a:visited,
a:focus,
a:hover,
a:active {
    text-decoration: none;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
    width: 6px;
}

::-webkit-scrollbar:horizontal {
    height: 0px;
}

::-webkit-scrollbar-thumb {
    border-radius: 5px;
    border: 0.15vw solid rgba(194, 194, 194, 0.53);
    background-color: rgba(201, 201, 201, 0.507);
}

::-webkit-scrollbar-track { 
    background-color: transparent; 
}