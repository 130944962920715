.mobile-awards-content {
    padding-top: 10%;
    padding-bottom: 5%;
    margin-bottom: 8%;
}

.mobile-awards-title {
    padding-bottom: 0vw;
    pointer-events: none;
}

.mobile-awards-container {
    display: grid;
    box-sizing: border-box;
    grid-template-rows: auto auto auto;
    row-gap: 4vh;
    margin-top: 5vh;
    pointer-events: none;
}

.mobile-non-state-topper-padding {
    margin-top: 5vh;
}

.mobile-awards-category {
    display: flex;
    flex-direction: column;
    border: 0.25vh solid black;
    border-radius: 0.5vh;
    background-color: #ffffff;
}

#MobileParticipantAwards {
    background-color: #b5b5b5;
}

#MobileStateAwards {
    background-color: #d7af49;
}

#MobileClassAwards {
    background-color: #b192e8;
    /*7abbd7*/
}

.mobile-awards-category-title {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    border-radius: 0.25vh;
}

.mobile-state-topper-category-title {
    grid-template-columns: 2fr 3fr;
}

.mobile-awards-category-title-text {
    display: flex;
    padding: 2vh;
    font-size: 3vh;
    font-weight: 600;
    color: #000000;
}

.mobile-awards-category-title-image {
    display: flex;
    width: 100%;
    align-items: center;
    /* To vertically center the image if needed */
    justify-content: center;
    /* To horizontally center the image if needed */
    overflow: hidden;
    /* To ensure nothing spills out of the container */
}

.mobile-non-state-topper-image {
    padding: 1vw 0vw;
}

.mobile-state-topper-image {
    padding: 1vw 0vw;
}

.mobile-awards-category-title-image img {
    width: 100%;
    height: auto;
    max-width: 100%;
    /* Ensure it doesn't grow beyond its container */
}

.mobile-awards-category-text {
    display: flex;
    border-radius: 0.25vw;
    border-top-right-radius: 0%;
    border-top-left-radius: 0%;
    background-color: #ffffff;
    padding: 2vw;
    flex-direction: column;
}

.mobile-awards-category-text-main {
    text-align: center;
    align-items: center;
    justify-content: center;
    border-radius: 1vh;
    padding: 2vh;
    font-weight: 700;
    font-size: 2.5vh;
    margin: 2vh;
}

.mobile-awards-category-text-main.participation {
    background-color: #ededed;
    margin-top: 0;
}

.mobile-awards-category-text-main.state {
    background-color: #ededed;
    margin-top: 0;
}

.mobile-awards-category-text-main.class {
    background-color: #ededed;
}

.mobile-awards-category-text-main.class.gold {
    background-color: #fae88d;
}

.mobile-awards-category-text-normal {
    text-align: center;
    align-items: center;
    justify-content: center;
    font-size: 1.75vh;
    padding: 2vh
}

.mobile-awards-scholarship-image {
    position: relative;
    width: 100%;
    padding: 1vh 1%;
    padding-bottom: 0;
    max-width: 100%;
    box-sizing: border-box;
}

.mobile-awards-scholarship-text {
    margin: 0;
    padding: 0;
    display: flex;
    padding-top: 7.5vw;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 9vw;
    font-weight: 700;
    color: #ffffff;
    letter-spacing: 0.1vh;
    -webkit-text-stroke: 0.125vh #dcca70;
}

.mobile-awards-guidelines-text {
    display: flex;
    justify-content: center;
    background-color: #fae6e6;
    margin: 3vh 0vh;
    margin-bottom: 0;
    padding: 1vh 2vh;
    border-radius: 0.5vh;
    font-size: 2vh;
    pointer-events: none;
}