.syllabus-container {
    align-items: center;
    margin-bottom: 1vw;
    /* spacing between class boxes */
    border-radius: 0.25vw;
    display: grid;
    grid-template-rows: 5vw 0vw;
    box-sizing: border-box;
    color: #000000;
    background-color: #95c2cc;
    transition: grid-template-rows 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.syllabus-container.mobile-expandable-container {
    align-items: center;
    margin-bottom: 2vh;
    /* spacing between class boxes */
    border-radius: 0.5vh;
    display: grid;
    grid-template-rows: 7.5vh 0vw;
    box-sizing: border-box;
    color: #000000;
    background-color: #95c2cc;
    transition: grid-template-rows 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.syllabus-container.expanded {
    grid-template-rows: 5vw auto;
    background-color: #ffffff;
}

.syllabus-container.expanded.mobile-expandable-container {
    grid-template-rows: 7.5vh auto;
    background-color: #ffffff;
}

.syllabus-type {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    cursor: pointer;
    border-radius: 0.25vw;
    border-bottom: 0.2vw solid transparent;
    background-color: #b5cace;
    transition: border ease 0.3s;
}

.mobile-expandable-container .syllabus-type {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    cursor: pointer;
    border-radius: 0.5vh;
    border-bottom: 0.5vh solid transparent;
    background-color: #b5cace;
    transition: border ease 0.3s;
}

.expanded .syllabus-type {
    border-bottom: 0.2vw solid #00146e2f;
    transition: border ease 0.3s;
}

.expanded.mobile-expandable-container .syllabus-type {
    border-bottom: 0.5vh solid #00146e2f;
    transition: border ease 0.3s;
}

.syllabus-type-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.75vw;
    font-weight: 700;
    border-radius: 0.5vw;
    margin-left: 2.5vw;
    height: 5vw;
    color: #383838;
    pointer-events: none;
}

.mobile-expandable-container .syllabus-type-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 2.75vh;
    font-weight: 700;
    border-radius: 0.5vh;
    margin-left: 2.5vh;
    height: 7.5vh;
    color: #383838;
}

.syllabus-type-expand {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1vw;
    margin-right: 2vw;
    height: 3vw;
    transition: all ease 0.3s;
}

.mobile-expandable-container .syllabus-type-expand {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1vh;
    margin-right: 2vh;
    height: 5vh;
    transition: all ease 0.3s;
}

.expanded .syllabus-type-expand {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    transition: all ease 0.3s;
}

.syllabus-details {
    opacity: 0;
    height: 0;
    overflow: hidden;
    transition: opacity 0s ease, height 0.3s ease-in-out;
    pointer-events: none;
}

.expanded .syllabus-details {
    opacity: 1;
    height: auto;
    transition: height 0.3s ease-in-out, opacity 0.1s ease 0.3s;
}

.syllabus-details h2 {
    padding-left: 2vw;
    font-size: 1.5vw;
}

.syllabus-details h4 {
    padding-left: 2vw;
    font-size: 1.25vw;
    font-weight: 400;
    line-height: 1.25vw;
}

.mobile-expandable-container .syllabus-details h2 {
    padding: 1vh 2vh;
    font-size: 2.25vh;
}

.mobile-expandable-container .syllabus-details h4 {
    padding: 0vh 2vh;
    font-size: 2.15vh;
    line-height: 2.5vh;
    font-weight: 400;
}

.syllabus-details p {
    padding-left: 4vw;
    font-size: 1.25vw;
    line-height: 1.25vw;
}

.mobile-expandable-container .syllabus-details p {
    padding: 0 4vh;
    font-size: 2vh;
    line-height: 2.5vh;
    text-align: left;
}

.syllabus-only-topic {
    display: grid;
    grid-template-columns: 2% 98%;
    border-bottom: 0.1vw solid #000000;
    padding-bottom: 0vw;
}

.mobile-expandable-container .syllabus-only-topic {
    grid-template-columns: 5% 95%;
    border-bottom: 0.1vh solid #000000;
    padding-bottom: 0vh;
}

.syllabus-one-detail {
    border-bottom: 0.1vw solid #000000;
    padding-bottom: 0.5vw;
}

.mobile-expandable-container .syllabus-one-detail {
    border-bottom: 0.1vh solid #000000;
    padding-bottom: 1vh;
}

.syllabus-reading-detail {
    padding-left: 0.5vw;
    padding-bottom: 0.5vw;
}

.mobile-expandable-container .syllabus-reading-detail {
    padding-left: 0.5vh;
    padding-bottom: 1vh;
}

.syllabus-writing-detail-top {
    border-bottom: 0.1vw solid #000000;
    padding-left: 0.5vw;
    padding-bottom: 0.5vw;
    padding-right: 1vw;
}

.mobile-expandable-container .syllabus-writing-detail-top{
    border-bottom: 0.1vh solid #000000;
    padding-bottom: 0.5vh;
    padding-left: 0.5vh;
}

.syllabus-writing-detail-bottom {
    padding-left: 0.5vw;
    padding-bottom: 0.5vw;
    padding-right: 1vw;
}

.mobile-expandable-container .syllabus-writing-detail-bottom{
    padding-bottom: 0.5vh;
    padding-left: 0.5vh;
}

.syllabus-puzzles-detail {
    border-bottom: 0.1vw solid #000000;
    padding-top: 0.5vw;
    padding-bottom: 0.5vw;
    padding-left: 0.5vw;
}

.mobile-expandable-container .syllabus-puzzles-detail{
    border-bottom: 0.1vh solid #000000;
    padding-bottom: 0.5vh;
    padding-left: 0.5vh;
}

.mobile-expandable-container .syllabus-puzzles-detail h2{
    padding-bottom: 0vh;
}

.pattern-details {
    flex-direction: column;
    background: linear-gradient(to right, #dfc9ba 0%, #dfc9ba 33%, #dfc9ba 100%);
    /* background: linear-gradient(to right, #06053b 0%, #1d1ba3 50%, #06053b 100%); */
    color: #ffffff;
    border-radius: 0.5vw;
    text-align: center;
    margin: 3vh 25%;
    border: 0.35vh double #ffffff;
    padding: 1vw 1vw;
}

.mobile-expandable-container .pattern-details {
    flex-direction: column;
    /* background: linear-gradient(to right, #06053b 0%, #1d1ba3 50%, #06053b 100%); */
    color: #ffffff;
    border-radius: 0.5vh;
    text-align: center;
    margin: 3vh 5%;
    border: 0.35vh double #ffffff;
    padding: 1vh 1vh;
}

.pattern-type {
    display: grid;
    grid-template-columns: 33% 65%;
    column-gap: 2%;
    padding: 0.5vw;
    font-size: 1.25vw;
}

.mobile-expandable-container .pattern-type {
    display: grid;
    grid-template-columns: 31% 67%;
    column-gap: 2%;
    padding: 0.5vh;
}

.pattern-left-name {
    background-color: #171730;
    color: #ffffff;
    border-radius: 0.25vw;
    text-align: center;
    padding: 0.5vw 0.5vw;
    height: 1.8vw;  
}

.mobile-expandable-container .pattern-left-name {
    border-radius: 0.5vh;
    align-content: center;
    justify-content: center;
    height: 5vh;  
    display: flex;
    align-items: center;
    align-self: center;
    font-size: 3.25vw;
}

.pattern-right-description {
    background-color: #0c425f;
    color: #ffffff;
    border-radius: 0.25vw;
    text-align: center;
    padding: 0.5vw 0vw;
}

.mobile-expandable-container .pattern-right-description {
    border-radius: 0.5vh;
    align-content: center;
    justify-content: center;
    display: flex;
    align-self: center;
    flex-direction: column;
    height: 5vh; 
    font-size: 3.25vw;
}

.pattern-questionsType {
    text-align: start;
}