.mobile-landingPage-logo img {
    width: auto;
    height: 6.5vh;
    border-radius: 1vh;
    transition: height 0.3s ease;
}

.mobile-landingPage-logo {
    width: auto;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: center;
    align-items: center;
    /* position: fixed; */
    z-index: 998;
    padding-top: 0.5vh;
    left: auto;
    transform: translateX(0%);
    transition: transform 0.75s ease, left 0.75s ease, top 0.3s ease, width 0.75 ease;
}

.mobile-landingPage-navbar-name-text-img {
    width: auto;
    padding-left: 0.5vh;
}

.mobile-landingPage-sidebar-logo img {
    width: auto;
    height: 8vh;
}

.mobile-landingPage-sidebar-logo {
    width: 100%;
    display: flex;
    padding-top: 1vw;
    justify-content: flex-start;
}

.mobile-landingPage-navbar-links {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.mobile-landingPage-side-drawer-icon {
    display: flex;
    height: 5vw;
    background-color: #ffffff;
    border-radius: 1vw;
    padding: 0vh;
}

.desktop-landingPage-navbar-logo {
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    padding: 1vw 0;
    padding-left: 2vw;
}

.desktop-landingPage-navbar-logo-img {
    width: auto;
    height: 3.5vw;
    border-radius: 0.5vw;
}

.desktop-landingPage-navbar-name-text-img {
    width: auto;
    height: 4vw;
    padding-left: 0.5vw;
}

.desktop-navbar {
    display: flex;
    justify-content: space-between; 
    width: 100%;
    transition: padding 0.3s ease;
}

.landingPage-navbar-links {
    display: grid;
    justify-content: flex-start;
    align-items: center;
    grid-template-columns: 11vw 11vw;
    column-gap: 6vw;
}

.landingPage-navbar-links-container-box {
    box-sizing: border-box;
}

.landingPage-navbar-links-container-box:active {
    background-color: rgba(0, 0, 0, 0);
}

.desktop-landingPage-navbar-links-container-box {
    display: inline-block;
    position: relative;
    padding: 0.35vw;
    font-size: 1.15vw;
    color: #ffffff;
    letter-spacing: 0.075vw;
    font-weight: 600;
    background-color: var(--category-color);
    border: 1px solid #1e4941;
    border-radius: 0.5vw;
    white-space: nowrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
}

.desktop-landingPage-navbar-links-container-box.gold-box {
    color: #000000;
    background-color: #ffc271;
}

.desktop-landingPage-navbar-links-container-box.black-box {
    color: #ffffff;
    background-color: #171717
}

.desktop-landingPage-navbar-links-container-box.gold-box::before {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    box-shadow: 0 0 0 0.1vw #ffffff;
    border: 0.15vw solid #ffc271;
    border-radius: 0.5vw;
}

.desktop-landingPage-navbar-links-container-box.section-active::before {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    box-shadow: 0 0 0 0.15vw #8b8b8b;
    border: 0.15vw solid #ffffff;
    border-radius: 0.5vw;
}

.desktop-landingPage-navbar-links-container-box.gold-box.section-active::before {
    border: 0.15vw solid #ffffff
}


.landingPage-navbar-links-container-text {
    text-decoration: none;
    display: block;
    position: relative;
    color: var(--color-fontColor);
    flex-direction: column;
    font-weight: 400;
    font-size: 2.5vh;
    margin: 0 2vw;
    cursor: default;
    justify-content: center;
    align-items: center;
}

.landingPage-navbar-links-container-text::before {
    content: "";
    position: absolute;
    width: 70%;
    height: 2px;
    bottom: 0;
    left: 2%;
    border-bottom: 2px solid transparent;
    border-radius: 1px;
}

.desktop-landingPage-navbar-links-container-text {
    text-decoration: none;
    display: block;
    position: relative;
    color: var(--color-fontColor);
    flex-direction: column;
    font-weight: 400;
    font-size: 2.5vh;
    margin: 0 2vw;
    cursor: default;
    justify-content: center;
    align-items: center;
}

.desktop-landingPage-navbar-links-container-text::before {
    content: "";
    position: absolute;
    width: 70%;
    height: 2px;
    bottom: 0;
    left: 2%;
    border-bottom: 2px solid transparent;
    border-radius: 1px;
}

.section-active::before {
    border-bottom: 2px solid black;
}

.newLink {
    overflow: hidden;
    max-width: 0; 
    opacity: 0;   
    margin: 0;
    display: grid;
    justify-content: center;
    align-items: center;
    background-color: #257d5b;
}

.newLink.visible {
    max-width: 15vw; 
    opacity: 1;       
    height: 5vw;
}

.desktop-newLink-container-box {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 0.2vw;
}

.desktop-newLink-container-text-box {
    font-size: 1.15vw;
    padding: 0.25vw 1vw;
    margin-right: 0.75vw;
    color: #000000;
    font-weight: 600;
    background-color: #55f236;
    border: 1px solid #000000;
    border-top-right-radius: 0.25vw;
    border-bottom-right-radius: 0.25vw;
    white-space: nowrap;
    justify-content: center;
    align-items: center;
    text-align: justify;
}

.desktop-newLink-book_store_img {
    width: auto;
    height: 3.5vw;
    margin-left: 1vw;
    justify-content: center;
    align-self: center;
    border: 1px solid #000000;
    border-top-left-radius: 0.25vw;
    border-bottom-left-radius: 0.25vw;
}