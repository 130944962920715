.desktop-themes-content {
    margin-top: 2%;
    margin-bottom: 0%;
}

.desktop-themes-title {
    padding-bottom: 5vw;
}

.desktop-themes-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    opacity: 0;
    pointer-events: none;
    margin: 0;
    /* Ensure there are no default margins */
    height: 100vh;
    /* Use the full viewport height */
    background: linear-gradient(to bottom, #00063d 0%, #00063d 35%, #057490 35%, #057490 100%);
    transition: opacity 0.3s ease;
    overflow: auto;
}

.desktop-themes-modal.open {
    opacity: 1;
    pointer-events: auto;
}

.desktop-themes-modal-content {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-rows: 17.5% 82.5%;
    grid-gap: 0px;
    margin: 0vw 4vw;
}

.desktop-modal-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.desktop-modal-logo-img {
    width: auto;
    height: 5vw;
}

.modal-top-row {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 30% 42.5% 27.5%;
}

.desktop-modal-class-title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2vw;
    font-weight: 600;
    color: #4b2323;
    background-color: #ffffff;
}

.desktop-modal-class-workbook-link {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5vw;
    font-weight: 600;
    color: #ffffff;
    border: 0.1vw solid #ffffff;
    border-radius: 1.5vw;
    margin: 2vw 3vw;
    margin-bottom: 2.5vw;
    white-space: nowrap;
    background-color: #000000;
    /*043a36,0b3d5c*/
}

.desktop-modal-close {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 1vw;
}

.desktop-modal-close img {
    height: 2vw;
    width: auto;
}

.modal-content-row {
    display: grid;
    grid-template-columns: 30% 70%;
}

.modal-classes-list {
    display: grid;
    margin-right: 1vw;
    padding-right: 2.5vw;
    margin-left: 1.5vw;
    overflow-y: scroll;
}

.modal-classes-list::-webkit-scrollbar {
    display: none;
    /* Hide scrollbar for Chrome, Safari, and Opera */
}

.class-container {
    align-items: center;
    margin-bottom: 1vw;
    /* spacing between class boxes */
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    /* Added border to the transition */
    aspect-ratio: 16/4;
    border-radius: 0.5vw;
    display: grid;
    grid-template-rows: 1fr 0fr;
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.75);
    box-sizing: border-box;
    background-color: #e5c9b9;
    border: 0.35vw double transparent;
    /* Added a transparent border so that it doesn't affect layout when it changes color */

}

.class-container.expanded {
    grid-template-rows: 1fr 1fr;
    transition: all 0.3s ease-in-out 0.3s, border 0.3s;
    /* Added a delay and also transition for border */
    aspect-ratio: 1/1;
    background-color: #ffffff;
    animation: tempBorder 0.3s forwards;
    /* Added an animation */
    cursor: default;
}

.class-container.scroll-triggered {
    animation: tempBorder 0.75s forwards;
    transition-delay: 0.75s;
    /* Longer delay when scrolled */
}

@keyframes tempBorder {

    0%,
    100% {
        border-color: transparent;
    }

    1% {
        border-color: rgb(0, 0, 0);
        background-color: #ffffff;
    }

    99% {
        border-color: rgb(0, 0, 0);
        background-color: #ffffff;
    }
}

.class-container-dummy {
    cursor: default;
    aspect-ratio: 16/4;
    box-sizing: border-box;
    background-color: transparent;
    margin-bottom: 2vw;
}

.class-image {
    height: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.expanded .class-image {
    height: 15vw;
    width: auto;
    transition: all 0.3s ease-in-out, height 0.3s ease-in-out 0.3s;
    /* Added delay for the height transition */
}

.scroll-triggered .class-image {
    height: 15vw;
    width: auto;
    transition: all 0.3s ease-in-out, height 0.3s ease-in-out 0.75s;
    /* Added delay for the height transition */
}

.class-image img {
    height: 15vw;
    width: auto;
}

.class-text {
    display: grid;
    grid-template-columns: 35% 65%;
}

.expanded .class-text {
    display: grid;
    grid-template-columns: 50% 50%;
    transition: all 0.3s ease-in-out 0.3s;
}

.scroll-triggered .class-text {
    display: grid;
    grid-template-columns: 50% 50%;
    transition: all 0.3s ease-in-out 0.75s;
}

.class-title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2vw;
    font-weight: 600;
    color: #4b2323;
}

.class-title-class-number {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 2vw;
    font-weight: 600;
    color: #4b2323;
}

.expanded .class-title-class-number {
    justify-content: center;
    font-size: 6vw;
    font-weight: 900;
    color: #000000;
    transition: all 0.3s ease-in-out 0.3s;
    /* Added delay for the height transition */
}

.scroll-triggered .class-title-class-number {
    justify-content: center;
    font-size: 6vw;
    font-weight: 900;
    color: #000000;
    transition: all 0.3s ease-in-out 0.75s;
    /* Added delay for the height transition */
}

.modal-syllabus-container {
    display: grid;
    grid-template-rows: 21% 79%;
    height: 82.5vh;
}

.modal-syllabus-top-section {
    display: grid;
    grid-template-columns: 80% 0%;
}

.modal-syllabus-controls {
    display: grid;
    grid-template-rows: 50% 50%;
}

.modal-syllabus-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 2.5vw;
    font-weight: 600;
    color: #ffffff;
}

.modal-syllabus-topic-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
    column-gap: 1vw;
}

.syllabus-topic-button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.25vw;
    font-weight: 500;
    color: #dfdfdf;
    border: 0.075vw solid #b3b3b3;
    border-radius: 0.5vw;
    white-space: nowrap;
    height: 2.5vw;
    background-color: #71717127;
    /*141414,71717127*/
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
}

.syllabus-topic-button:active {
    background-color: #2378a594;
}

.modal-class-controls {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    justify-items: center;
    margin: 0.75vw;
    margin-right: 0;
    margin-left: 4vw;
    column-gap: 0.25vw;
    background-color: #71717163;
    box-shadow: 0.005vw 0.005vw rgb(136, 207, 255),
        0.4vw 0.4vw 0.625vw rgb(136, 207, 255);
        /* box-shadow: 0 0 0.5vw 0.75vw rgb(5, 0, 48); */
    border-radius: 0.5vw;
}

.modal-class-control-prev-button {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-class-control-prev-button img {
    height: 4.5vw;
    width: auto;
}

.modal-class-control-class-display {
    display: grid;
    grid-template-rows: 40% 60%;
    align-items: flex-end;
    justify-items: center;
    justify-content: center;
    height: 80%;
    width: 100%;
    border-radius: 0.5vw;
    font-size: 1.25vw;
    text-align: center;
    color: #000000;
    background-color: #ffffffd3;
    border-radius: 0.5rgba(225, 225, 225, 0.686);
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
}

.modal-class-control-class-display-number{
    align-self: flex-start;
    font-size: 2vw;
    font-weight: 700;
}

.modal-class-control-next-button {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-class-control-next-button img {
    height: 4.5vw;
    width: auto;
}

.modal-syllabus-bottom-container {
    margin-top: 0.1vw;
    padding-top: 1vw;
    overflow-y: auto;   /* will add vertical scroll if the content exceeds max-height */
}

.modal-syllabus-bottom-container::-webkit-scrollbar {
    display: none;
    /* Hide scrollbar for Chrome, Safari, and Opera */
}