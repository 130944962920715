.guidelines {
    background-color: #ffffff;
    display: flex;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    background-color: #ffffff;
    margin-bottom: 0;
}

.guidelines-content {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 7.5%;
    width: 90%;
}

.guidelines-title {
    text-align: center;
    color: #000000;
    font-weight: 700;
}

.guidelines-container {
    display: flex;
    box-sizing: border-box;
    height: 100%;
    flex-direction: column;
    margin: 2%;
}

.guidelines-container-mobile {
    display: block;
    box-sizing: border-box;
    margin-top: 7%;
}

.mobile-guidelines-content {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    margin: 10%;
    margin-top: 0%;
    width: 90%;
}

.navbar-otherpages {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #ffffff;
    border-bottom: 1px solid black;
    /* Customize your navbar background color */
    color: #fff;
    /* Customize the color of the text */
    display: flex;
    align-items: center;
    z-index: 1000;
    height: 5vw;
    padding-left: 0;
    padding-top: 0
}

.navbar-otherpages.mobile {
    height: 10vh;
    padding: 0 1rem;
}

.navbar-otherpages-icon {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: left;
}

.navbar-otherpages-icon img {
    width: auto;
    padding-left: 2vw;
    height: 4.75vw;
}

.navbar-otherpages-icon.mobile img {
    height: 7.5vh;
    padding-left: 0;
    padding-top: 0.5vh;
}

.guidelines-content-container {
    flex: 1;
    display: flex;
    background-color: rgb(255, 255, 255);
}

.guidelines-contents {
    overflow: overlay;
    scroll-behavior: smooth;
    text-align: justify;
}

.guidelines-contents::-webkit-scrollbar {
    width: 4px;
}

.guidelines-contents::-webkit-scrollbar-thumb {
    background-color: rgb(98, 98, 98);
}