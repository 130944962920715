.themes {
    background-color: #00063d;
    display: flex;
    box-sizing: border-box;
}

.themes-content {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 2%;
    width: 90%;
}

.themes-title {
    text-align: center;
    color: #ffffff;
    font-weight: 700;
}

.all-themes-container {
    display: grid;
    box-sizing: border-box;
    height: 100%;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 3.25%;
    row-gap: 8%;
    margin: 6%;
    margin-top: 2%;
    background-color: #057490;
    padding: 3%;
    padding-bottom: 9%;
    border-radius: 0.5vw;
}

.all-themes-container-mobile {
    display: block;
    box-sizing: border-box;
    margin-top: 7%;
}

.mobile-themes-content {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    margin-top: 0%;
    width: 100%;
}

.category-container-mobile-outside {
    padding: 5%;
}

.single-theme-category-container {
    aspect-ratio: 16/9;
    border-radius: 0.5vw;
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: 1fr 1fr;
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.75);
    box-sizing: border-box;
    animation-duration: 15s;
    /* Adjust the animation duration as needed */
    animation-timing-function: ease-in-out;
    /* Adjust the animation timing function as needed */
    animation-iteration-count: infinite;
    /* Infinite animation loop */
    cursor: pointer;
}

.zoom-effect {
    animation-timing-function: ease-in-out;
    /* Adjust the animation timing function as needed */
    animation-iteration-count: infinite;
    /* Infinite animation loop */
}

@keyframes zoomIn {
    0% {
        transform: scale(1);
        z-index: 1;
        border: none;
    }

    1% {
        transform: scale(1.25);
        z-index: 2;
        border: 0.05vw solid #eaeaea;
        /* Zoom in effect */
    }
    2% {
        transform: scale(1.25);
        z-index: 2;
        border: 0.35vw solid #d0d0d0;
        /* Zoom in effect */
    }

    3% {
        border: 0.35vw solid #b1b1b1;
    }

    5% {
        border: 0.35vw solid #929292;
    }

    7% {
        border: 0.35vw solid #b1b1b1;
    }

    8% {
        transform: scale(1.25);
        z-index: 2;
        border: 0.35vw solid #d0d0d0;
        /* Zoom in effect */
    }
    9% {
        transform: scale(1);
        z-index: 1;
        border: 0.05vw solid #eaeaea;
        /* Zoom in effect */
    }

    10% {
        transform: scale(1);
        z-index: 1;
        border: 0vw solid #ffffff;
        /* Zoom in effect */
    }

    100% {
        transform: scale(1);
        z-index: 1;
    }
}

@keyframes mobile-zoomIn {
    0% {
        transform: scale(1);
    }

    1% {
        transform: scale(1.075);
        /* Zoom in effect */
    }

    2% {
        transform: scale(1);
        /* Zoom in effect */
    }

    100% {
        transform: scale(1);
    }
}

/* Category 1 */
#CategoryContainer1.zoom-effect {
    animation-name: var(--zoomDepth);
    animation-delay: 0s;
    /* No delay for the first category */
}

/* Category 2 */
#CategoryContainer2.zoom-effect {
    animation-name: var(--zoomDepth);
    animation-delay: 1.25s;
    /* Delay of 0.5 seconds for the second category */
}

/* Category 3 */
#CategoryContainer3.zoom-effect {
    animation-name: var(--zoomDepth);
    animation-delay: 2.5s;
    /* Delay of 1 second for the third category */
}

/* Category 4 */
#CategoryContainer4.zoom-effect {
    animation-name: var(--zoomDepth);
    animation-delay: 3.75s;
    /* Delay of 1.5 seconds for the fourth category */
}

/* Category 5 */
#CategoryContainer5.zoom-effect {
    animation-name: var(--zoomDepth);
    animation-delay: 5s;
    /* No delay for the first category */
}

/* Category 6 */
#CategoryContainer6.zoom-effect {
    animation-name: var(--zoomDepth);
    animation-delay: 6.25s;
    /* Delay of 0.5 seconds for the second category */
}

/* Category 7 */
#CategoryContainer7.zoom-effect {
    animation-name: var(--zoomDepth);
    animation-delay: 7.5s;
    /* Delay of 1 second for the third category */
}

/* Category 8 */
#CategoryContainer8.zoom-effect {
    animation-name: var(--zoomDepth);
    animation-delay: 8.75s;
    /* Delay of 1.5 seconds for the fourth category */
}

/* Category 9 */
#CategoryContainer9.zoom-effect {
    animation-name: var(--zoomDepth);
    animation-delay: 10s;
    /* No delay for the first category */
}

/* Category 10 */
#CategoryContainer10.zoom-effect {
    animation-name: var(--zoomDepth);
    animation-delay: 11.25s;
    /* Delay of 0.5 seconds for the second category */
}

/* Category 11*/
#CategoryContainer11.zoom-effect {
    animation-name: var(--zoomDepth);
    animation-delay: 12.5s;
    /* Delay of 1 second for the third category */
}

/* Category 12*/
#CategoryContainer12.zoom-effect {
    animation-name: var(--zoomDepth);
    animation-delay: 13.75s;
    /* Delay of 1.5 seconds for the fourth category */
}

.mobile-single-theme-category-container {
    height: auto;
    border-radius: 2vw;
    border: 0.6vw solid #e3e9e8;
    box-shadow: 2vw 2vw 3vw rgba(0, 0, 0, 0.5);
}

.single-theme-category-container>* {
    /* This targets all direct children of the container */
    min-width: 0;
    /* This prevents the children from overflowing the container horizontally */
    min-height: 0;
    /* This prevents the children from overflowing the container vertically */
}

.theme-category-text {
    padding: 7.5%;
    display: grid;
    grid-template-rows: auto auto;
    color: #000000;
}

.theme-category-title {
    display: flex;
    justify-content: center;
    align-items: start;
    font-size: 1.75vw;
    font-weight: 700;
}

.theme-category-title-class-number {
    display: flex;
    justify-content: center;
    align-items: start;
    font-size: 4vw;
    font-weight: 900;
}

.mobile-theme-category-title {
    font-size: 4vw;
    font-weight: 800;
}

.theme-category-image {
    aspect-ratio: 1/1;
    align-self: center;
    justify-content: center;
    padding-right: 2%;
    display: flex;
}

.theme-category-image img {

    width: 100%;
    height: 100%;
}

.theme-category-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1vw;
    min-width: 0;
    /* This prevents the children from overflowing the container horizontally */
    min-height: 0;
}

.mobile-theme-category-buttons {
    column-gap: 2vw;
}

.theme-category-buttons>* {
    /* This targets all direct children of the container */
    min-width: 0;
    /* This prevents the children from overflowing the container horizontally */
    min-height: 0;
    /* This prevents the children from overflowing the container vertically */
}

.button-column {
    display: grid;
    grid-template-rows: 1fr 1fr;
    align-items: end;
}

.themes-class-button {
    color: white;
    background-color: #073630;
    justify-content: center;
    height: 70%;
    font-size: 1.15vw;
    font-weight: 600;
    border: none;
    border-radius: 0.75vw;
}

.mobile-themes-class-button {
    font-size: 2.75vw;
    font-weight: 600;
    letter-spacing: 0.2vw;
    border-radius: 1.75vw;
}

.themes-image {
    overflow-x: auto;
}

/* 
.themes-image::-webkit-scrollbar {
    width: .75vw;
}

.themes-image::-webkit-scrollbar-track {
    background-color: white;
}

.themes-image::-webkit-scrollbar-thumb {
    border-radius: 2px;
    border: 1px solid rgba(43, 89, 18, 0.53);
    background-color: rgba(155, 140, 5, 0.735);
} */

.themes-button {
    text-decoration: none;
    cursor: default;
    font-weight: 600;
    color: var(--color-kLandingPageBackgroundColor);
    display: flex;
    justify-self: center;
    justify-content: center;
    align-content: center;
    align-self: center;
    align-items: center;
    background-color: rgb(14, 103, 73);
}

.image-modal {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.8);
}

.themes-modal-close-icon {
    border: none;
    display: inline-block;
    background-color: rgb(207, 108, 108);
    border-radius: 15%;
    vertical-align: middle;
    overflow: hidden;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    white-space: nowrap
}

.topright {
    position: absolute;
    right: 0;
    top: 0
}

.loading-shimmer {
    animation: fadeInFromNone 1.75s linear infinite;
}

@keyframes fadeInFromNone {
    0% {
        opacity: 0.5;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0.5;
    }
}

.loading-spinner {
    height: 60px;
    width: 60px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -30px 0 0 -30px;
    -webkit-animation: loading-spinner-rotation 1s infinite linear;
    -moz-animation: loading-spinner-rotation 1s infinite linear;
    -o-animation: loading-spinner-rotation 1s infinite linear;
    animation: loading-spinner-rotation 1s infinite linear;
    border: 6px solid rgba(0, 0, 0, 0.2);
    border-radius: 100%;
}

.loading-spinner:before {
    content: "";
    display: block;
    position: absolute;
    left: -6px;
    top: -6px;
    height: 100%;
    width: 100%;
    border-top: 6px solid rgba(209, 209, 209, 0.8);
    border-left: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 6px solid transparent;
    border-radius: 100%;
}

@-webkit-keyframes loading-spinner-rotation {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(359deg);
    }
}

@-moz-keyframes loading-spinner-rotation {
    from {
        -moz-transform: rotate(0deg);
    }

    to {
        -moz-transform: rotate(359deg);
    }
}

@-o-keyframes loading-spinner-rotation {
    from {
        -o-transform: rotate(0deg);
    }

    to {
        -o-transform: rotate(359deg);
    }
}

@keyframes loading-spinner-rotation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
}

.playpause-button {
    position: fixed;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    opacity: 1;
    box-shadow: 0.1vw 0.1vw 0.5vw inset #bdbdbd;
    transition: transform 0.3s, background-color 0.3s;
}

.playpause-button.play {
    animation-delay: 0.5s;
    box-shadow: 0.1vw 0.1vw 0.5vw inset rgba(0, 0, 0, 0.3);
}

.playpause-button img {
    width: auto;
    height: 2vw;
}

.mobile-playpause-button {
    position: fixed;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    opacity: 1;
    z-index: 999;
    box-shadow: 0.25vw 0.25vw 1vw inset #bdbdbd;
    transition: transform 0.3s, background-color 0.3s;
}

.mobile-playpause-button.play {
    animation-delay: 0.5s;
    box-shadow: 0.25vw 0.25vw 1vw inset rgba(0, 0, 0, 0.3);
}

.mobile-playpause-button img {
    width: auto;
    height: 2.5vh;
}

.loader-spinner {
    width: 3vw;
    height: 3vw;
    margin-top: 5vh;
    display: flex;
    border-radius: 50%;
    border: 0.45vw solid #bdbdbd;
    border-top-color: #0e3b34;
    animation: spin 1s infinite linear;
}

.mobile-loader-spinner {
    width: 10vw;
    height: 10vw;
    margin-top: 10vh;
    display: flex;
    border-radius: 50%;
    border: 1vw solid #bdbdbd;
    border-top-color: #0e3b34;
    animation: spin 1s infinite linear;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}