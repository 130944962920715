.footer {
    background-color: white;
    border-top: 2px solid black;
    display: flex;
    flex-direction: column;
    padding: 0px 1rem;
}

.footer-logo {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto;

}

.footer-logo img {
    height: 3.5vw;
    width: auto;
    border-radius: 0.5vw;
}

.footer-logo-img {
    justify-self: end;
}

.footer-name-text-img {
    padding-left: 0.5vw;
}

.mobile-footer-logo {
    width: 100%;
    display: flex;
    justify-content: center;
}

.mobile-footer-logo img {
    height: 4vh;
    border-radius: 0.5vh;
}

.mobile-footer-logo-text {
    padding-left: 0.25rem;
    font-size: 2.5vw;
    white-space: nowrap;
}

.mobile-footer-name-text-img {
    padding-left: 0.5vh;
}

.footer-top{
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
}

.mobile-footer-top{
    padding-top: 2.5vw;
    padding-bottom: 5vw;
}

.footer-top-left {
    display: flex;
    flex-direction: column;
    padding: 1rem 1rem;
}

.mobile-footer-top-left {
    padding: 1rem 0rem;
}

.footer-logo-text {
    font-size: 1.05vw;
    pointer-events: none;
}

.footer-divider {
    height: 0.1vw;
    width: 70%;
    align-self: center;
    background-color: rgba(68, 86, 152, 0.9);
}

.footer-bottom {
    justify-self: center;
    align-self: center;
    font-size: 1vw;
}

.mobile-footer-bottom {
    display: flex;
    flex-direction: column;
    justify-self: center;
    align-items: center;
    font-size: 2.5vw;
    pointer-events: none;
}

.footer-terms-button {
    text-decoration: none;
    color: black;
    cursor: pointer;
    padding-right: 1rem;
}

.mobile-footer-terms-button {
    text-decoration: none;
    color: black;
    cursor: pointer;
    white-space: nowrap;
    font-size: 3vw;
    padding-right: 0;
}

.mobile-tnc-container-box {
    display: inline-block;
    position: relative;
    padding: 1vw;
    font-size: 3.25vw;
    color: #ffffff;
    background-color: #3e3e3e;
    letter-spacing: 0.075vw;
    font-weight: 600;
    border: 1px solid #3e3e3e;
    border-radius: 0.5vw;
    white-space: nowrap;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.mobile-tnc-container-box::before {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    box-shadow: 0 0 0 0.1vw #3e3e3e;
    border: 0.35vw solid #3e3e3e;
    border-radius: 0.75vw;
}

.tnc-container-box {
    display: inline-block;
    position: relative;
    padding: 0.35vw;
    font-size: 1.15vw;
    color: #ffffff;
    background-color: #3e3e3e;
    letter-spacing: 0.075vw;
    font-weight: 600;
    border: 1px solid #3e3e3e;
    border-radius: 0.5vw;
    white-space: nowrap;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.tnc-container-box::before {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    box-shadow: 0 0 0 0.1vw #3e3e3e;
    border: 0.35vw solid #3e3e3e;
    border-radius: 0.25vw;
}